<template>
  <filter-item-wrapper title="Дата" icon="menu_icon9" collapse="collapse-date">
    <template #clear>
      <span
        v-if="existsTurnOnFilterDate"
        @click="resetFilterDates"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="row">
        <div class="col-6">
          <div class="form-group form-group-filter">
            <label class="filter-modal-label">Від</label>
            <b-input-group class="mb-3">
              <b-form-input
                id="example-input"
                v-model="valueFrom"
                type="text"
                size="sm"
                placeholder="DD.MM.YYYY"
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  right
                  v-model="valueFrom"
                  calendar-width="240px"
                  size="sm"
                  locale="ua"
                  class="from-datepicker"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group form-group-filter">
            <label class="filter-modal-label">До</label>
            <b-input-group class="mb-3">
              <b-form-input
                id="example-input"
                v-model="valueTo"
                type="text"
                size="sm"
                placeholder="DD.MM.YYYY"
                autocomplete="off"
              ></b-form-input>

              <b-input-group-append>
                <b-form-datepicker
                  v-model="valueTo"
                  button-only
                  right
                  calendar-width="240px"
                  size="sm"
                  locale="ua"
                  aria-controls="example-input to-datepicker"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import { transformDate } from '@/utils/transform-date';
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'FilterDate',
  mixins: ['transformDate'],
  components: {
    FilterItemWrapper
  },
  methods: {
    ...mapMutations({
      changeValueFrom: 'CHANGE_VALUE_FROM',
      changeValueTo: 'CHANGE_VALUE_TO',
      resetFilterDates: 'RESET_FILTER_DATES'
    })
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterDate: 'existsTurnOnFilterDate'
    }),
    valueFrom: {
      get() {
        return this.$store.state.filter_date.form.updated_from;
      },
      set(date) {
        if (date) {
          this.changeValueFrom(transformDate(date));
        }
      }
    },
    valueTo: {
      get() {
        return this.$store.state.filter_date.form.updated_to;
      },
      set(date) {
        if (date) {
          this.changeValueTo(transformDate(date));
        }
      }
    }
  }
};
</script>

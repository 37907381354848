export const transformDate = (val) => {
  const timestamp = new Date(val);
  const year = timestamp.getFullYear();
  let month = timestamp.getMonth();
  const day = timestamp.getDate();
  month += 1;
  const formatted = [
    day.toString().padStart(2, '0'),
    month.toString().padStart(2, '0'),
    year.toString().padStart(2, '0')
  ].join('.');

  return formatted;
};

<template>
  <filter-item-wrapper
    title="Додатково"
    icon="menu_icon12"
    collapse="collapse-options"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterOptions"
        @click="resetFilterOptions"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="">
        <div v-for="(item, i) in listOptions" :key="i" class="w-100">
          <e-checkbox
            v-model="selectedOptions"
            :value="item.value"
            :label="item.label"
            class="w-100"
          />
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { objectToArray } from '@/utils/object-to-array';
import { mapMutations, mapGetters, mapState } from 'vuex';
import { ADDITIONS } from '@/helpers/constants';
import eCheckbox from '@/elements/inputs/e-checkbox';
import union from 'lodash/union';

export default {
  name: 'FilterOptions',
  components: {
    FilterItemWrapper,
    eCheckbox
  },
  methods: {
    ...mapMutations({
      changeOptions: 'CHANGE_OPTIONS',
      resetFilterOptions: 'RESET_FILTER_OPTIONS'
    })
  },
  computed: {
    ...mapState({
      aptTypes: (state) => state.filter_type.form.apt_type
    }),
    ...mapGetters({
      existsTurnOnFilterOptions: 'existsTurnOnFilterOptions'
    }),
    selectedOptions: {
      get() {
        return this.$store.state.filter_options.form.options;
      },
      set(data) {
        this.changeOptions(data);
      }
    },
    listOptions() {
      const array = objectToArray(ADDITIONS);

      let arrayIndexes = [];
      this.aptTypes.forEach((type) => {
        switch (type.id) {
          case 'restaurant_or_cafe_or_bar':
          case 'beauty_object':
          case 'health_object':
          case 'sport_object':
          case 'event_object':
          case 'shop':
            arrayIndexes = arrayIndexes.concat([0, 1, 3]);
            break;
          case 'office_room':
            arrayIndexes = arrayIndexes.concat([0, 1, 2, 3]);
            break;
          case 'warehouse_or_hangar':
            arrayIndexes = arrayIndexes.concat([0, 3, 4, 5]);
            break;
          case 'part_of_the_building':
          case 'detached_building':
            arrayIndexes = arrayIndexes.concat([0, 1]);
            break;
          case 'recreation_center_or_hotel':
            arrayIndexes = arrayIndexes.concat([6, 7, 8, 9]);
            break;
          case 'industrial_premise':
          case 'production_object':
          case 'production_object':
          case 'free_appointment_premise':
            arrayIndexes = arrayIndexes.concat([0, 4]);
            break;
          case 'beauty_object':
          case 'health_object':
          case 'sport_object':
          case 'event_object':
          case 'autoservice':
            arrayIndexes = arrayIndexes.concat([10, 11, 12, 13, 14]);
            break;
        }
      });

      if (arrayIndexes.length > 0) {
        return array.filter((val, index) =>
          union(arrayIndexes).includes(index)
        );
      }
      return array.slice(0, 14);
    }
  }
};
</script>

<template>
  <filter-item-wrapper
    title="Кадастровий номер"
    icon="cadastral_number"
    collapse="collapse-cadastral-number"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterCadastralNumber"
        class="clear-section-filter"
        @click="clear"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="row">
        <div class="col-12">
          <div class="form-group form-group-filter">
            <label
              for="asideFilterCadastralNumberControl"
              class="filter-modal-label"
            >
              Введіть кадастровий номер
            </label>

            <input
              type="text"
              v-model="cadastral_number"
              id="asideFilterCadastralNumberControl"
              v-mask="'##########:##:###:####'"
              placeholder="XXXXXXXXXX:XX:XXX:XXXX"
              class="form-input"
            />
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapMutations, mapGetters, mapState } from 'vuex';

export default {
  name: 'FilterCadastralNumber',
  components: {
    FilterItemWrapper
  },
  methods: {
    ...mapMutations({
      changeCadastralNumber: 'CHANGE_FILTER_CADASTRAL_NUMBER',
      resetFilterCadastralNumber: 'RESET_FILTER_CADASTRAL_NUMBER'
    }),
    sorting(type, field) {
      this.$refs[field].classList.add('active');
      this.$refs[type].classList.remove('active');
    },
    clear() {
      this.resetFilterCadastralNumber();
    }
  },
  computed: {
    ...mapState({
      businessType: (state) => state.filter_header.businessType
    }),
    ...mapGetters({
      existsTurnOnFilterCadastralNumber: 'existsTurnOnFilterCadastralNumber'
    }),
    cadastral_number: {
      get() {
        return this.$store.state.filter_lands_cadastral_number.form
          .cadastral_number;
      },
      set(data) {
        this.changeCadastralNumber(data);
      }
    }
  }
};
</script>

<template>
  <filter-item-wrapper
    title="Комунікації"
    icon="menu_icon2"
    collapse="collapse-lands-communications"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterLandsCommunications"
        class="clear-section-filter"
        @click="resetFilterLandsCommunications"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="form-row">
        <div class="col-12">
          <ul class="pl-0 list-unstyled">
            <li
              v-for="(comm, commIndex) in land_communications_list"
              :key="commIndex"
              class="btn-group-toggle"
            >
              <label class="btn test-1">
                <input
                  type="checkbox"
                  v-model="communications"
                  :value="comm.value"
                />

                <div class="btn-check-border">
                  <icon name="check_icon" class="btn-check-icon" />
                </div>

                <span>&nbsp;&nbsp;{{ comm.label }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { LAND_COMMUNICATIONS } from '@/helpers/constants';
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import eCheckbox from '@/elements/inputs/e-checkbox';

const land_communications_list = Object.entries(LAND_COMMUNICATIONS).map(
  ([key, label]) => ({
    value: key,
    label: label
  })
);

export default {
  name: 'FilterLandsCommunications',
  components: {
    FilterItemWrapper,
    eCheckbox
  },
  data() {
    return {
      land_communications_list
    };
  },
  methods: {
    ...mapMutations({
      changeLandsCommunications: 'CHANGE_FILTER_LANDS_COMMUNICATIONS',
      resetFilterLandsCommunications: 'RESET_FILTER_LANDS_COMMUNICATIONS'
    })
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterLandsCommunications:
        'existsTurnOnFilterLandsCommunications'
    }),
    communications: {
      get() {
        return this.$store.state.filter_lands_communications.form
          .communications;
      },
      set(data) {
        this.changeLandsCommunications(data);
      }
    }
  }
};
</script>

<style lang="sass">
#clear-icon
  font-size: 16px
  color: white

.clear-section-filter
  padding-top: 10px

  &:hover
    cursor: pointer
</style>

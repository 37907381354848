<template>
  <div class="header-filter pt-3 pb-1">
    <div class="search-block-id">
      <div class="search-block-id__input">
        <input
          type="number"
          v-model="searchId"
          placeholder="Введіть id об'єкту"
          @keydown.enter="searchById"
        />
      </div>

      <button class="search-block-id__btn btn-filter" @click="searchById">
        Пошук
      </button>
    </div>

    <div class="filter-main-title d-flex align-items-center">
      <div class="base-text">БАЗА</div>

      <div class="ml-2 min-w-0">
        <b-dropdown
          class="dropdown-apartments"
          :text="type_constant_plural[$store.state.filter_header.buildingType]"
        >
          <b-dropdown-item
            v-for="(type, typeKey) in types"
            :key="typeKey"
            id="dropdown-menu"
            @click="updateBuildingType(typeKey)"
          >
            <div class="d-flex align-items-center">
              <span class="mr-1">{{ type }}</span>

              <span v-if="typeKey === 'land'" class="new-label">нове</span>

              <span v-if="typeKey === 'garage'" class="status-options">
                (скоро)
              </span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="ml-2">
        <b-dropdown class="dropdown-apartments" :text="getNameBusinessType">
          <b-dropdown-item
            v-for="(type, typeKey) in businessType"
            :key="typeKey"
            id="dropdown-menu"
            @click="updateBusinessType(type.key)"
          >
            {{ type.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import LabelsMixin from '@/mixins/LabelsMixin';

export default {
  name: 'FilterHeader',
  mixins: [LabelsMixin],
  data() {
    return {
      businessType: [
        {
          key: 'sell',
          name: 'Продам'
        },
        {
          key: 'rent',
          name: 'Здам'
        }
      ]
    };
  },
  methods: {
    updateBuildingType(type) {
      this.$store.commit('SET_BUILDING_TYPE', type);
      this.$store.commit('SET_SEARCH_ID', '');
      this.updateFilter();
    },
    updateBusinessType(key) {
      this.$store.commit('SET_BUSINESS_TYPE', key);
      this.$store.commit('SET_SEARCH_ID', '');
      this.updateFilter();
    },
    updateFilter(isSearchById = false) {
      if (window.innerWidth > 991) {
        // document.getElementsByClassName(
        //   'simplebar-content-wrapper'
        // )[0].scrollLeft = 0; // for desktop
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // for mobile
      }

      if (!isSearchById) {
        this.$store.dispatch('resetFilters');
      }

      this.$emit('forceRerender');
      this.$store.commit('SET_PAGE', 1);
      this.$store.dispatch('getObjects', { isSearchById });
    },
    searchById() {
      if (!this.$store.state.filter_header.searchId) {
        this.$notify({
          group: 'app',
          type: 'info',
          text: 'id не може бути пустим'
        });
        return;
      }

      this.updateFilter(true);
    }
  },
  computed: {
    getNameBusinessType() {
      return this.businessType.find(
        (i) => i.key === this.$store.state.filter_header.businessType
      ).name;
    },
    searchId: {
      get() {
        return this.$store.state.filter_header.searchId;
      },
      set(val) {
        this.$store.commit('SET_SEARCH_ID', val);
      }
    }
  }
};
</script>

import constants from './ConstantComunications';

const communicationsLabelsMixin = {
  data() {
    return {
      water_data: constants.water_data,
      gas_data: constants.gas_data,
      sewers_data: constants.sewers_data,
      heating_data: constants.heating_data
    };
  }
};

export default communicationsLabelsMixin;

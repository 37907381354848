<template>
  <filter-item-wrapper
    title="Тип"
    icon="menu_icon2"
    collapse="collapse-lands-purpose"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterLandsPurpose"
        class="clear-section-filter"
        @click="resetFilterLandsPurpose"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <ul class="list-unstyled mb-0">
        <li
          v-for="(typeLabel, typeValue) in land_types"
          :key="typeValue"
          class="btn-group-toggle form-checkbox-b"
          data-toggle="buttons"
        >
          <label class="btn test-1">
            <input type="checkbox" v-model="purpose" :value="typeValue" />

            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>

            <span>&nbsp;&nbsp;{{ typeLabel }}</span>
          </label>
        </li>
      </ul>
    </template>
  </filter-item-wrapper>
</template>

<script>
import LabelsMixin from '@/mixins/LabelsMixin';
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'FilterLandsPurpose',
  mixins: [LabelsMixin],
  components: {
    FilterItemWrapper
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterLandsPurpose: 'existsTurnOnFilterLandsPurpose'
    }),
    purpose: {
      get() {
        return this.$store.state.filter_lands_purpose.form.purpose;
      },
      set(data) {
        this.changeFilterLandsPurpose(data);
      }
    }
  },
  methods: {
    ...mapMutations({
      changeFilterLandsPurpose: 'CHANGE_FILTER_LANDS_PURPOSE',
      resetFilterLandsPurpose: 'RESET_FILTER_LANDS_PURPOSE'
    })
  }
};
</script>
